<template>
  <div class="payment-content">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Payments and Transfers</h3>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-md-6 d-grid">
                  <div class="card mb-4 bg-light-gray border-0">
                    <div class="card-header p-3 bg-transparent border-0">
                      <h3 class="mb-0 text-sm">GBP Payment</h3>
                    </div>

                    <div class="card-body text-xs p-3">
                      Make payment on GBP currency (UK only)
                    </div>
                    <div
                      class="card-footer bg-transparent border-0 p-1 pr-3 pb-3"
                    >
                      <div class="text-right">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/payment/gbp?type=local-gbp')"
                          class="btn btn-primary btn-sm"
                          >Make Payment GBP</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 d-grid">
                  <div class="card mb-4 bg-light-gray border-0">
                    <div class="card-header p-3 bg-transparent border-0">
                      <h3 class="mb-0 text-sm">EUR payment (SEPA)</h3>
                    </div>

                    <div class="card-body text-xs p-3">
                      Make payment on EUR currency (SEPA)
                    </div>
                    <div
                      class="card-footer bg-transparent border-0 p-1 pr-3 pb-3"
                    >
                      <div class="text-right">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/payment/eur?type=locat-eur')"
                          class="btn btn-primary btn-sm"
                          >Make Payment EUR</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 d-grid">
                  <div class="card mb-4 bg-light-gray border-0">
                    <div class="card-header p-3 bg-transparent border-0">
                      <h3 class="mb-0 text-sm">THB payment (Thailand)</h3>
                    </div>

                    <div class="card-body text-xs p-3">
                      Make payment to THB Thailand
                    </div>
                    <div
                      class="card-footer bg-transparent border-0 p-1 pr-3 pb-3"
                    >
                      <div class="text-right">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/payment/thb?type=local-thb')"
                          class="btn btn-primary btn-sm"
                          >Make Payment THB</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 d-grid" v-if="isSwift">
                  <div class="card mb-4 bg-light-gray border-0">
                    <div class="card-header p-3 bg-transparent border-0">
                      <h3 class="mb-0 text-sm">SWIFT PAYMENT WORLDWIDE</h3>
                    </div>

                    <div class="card-body text-xs p-3">
                      Make swift payment up to {{ countCurrency }} Currencies
                      across the world
                    </div>
                    <div
                      class="card-footer bg-transparent border-0 p-1 pr-3 pb-3"
                    >
                      <div class="text-right">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/payment/swt?type=inter-same')"
                          class="btn btn-primary btn-sm"
                          >Make Swift payment</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 d-grid" v-if="accessRole.instant">
                  <div class="card mb-4 bg-light-gray border-0">
                    <div class="card-header p-3 bg-transparent border-0">
                      <h3 class="mb-0 text-sm">Instant payment</h3>
                    </div>

                    <div class="card-body text-xs p-3">
                      Free payment between AB PLUS by phone number or email
                    </div>
                    <div
                      class="card-footer bg-transparent border-0 p-1 pr-3 pb-3"
                    >
                      <div class="text-right">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/instant-bank-payment')"
                          class="btn btn-primary btn-sm"
                          >Make Instant payment</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <Shortcut />
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeviceSettingDialog />
  </div>
</template>
<script>
import DeviceSettingDialog from "@/views/Device/SettingDialog.vue";
import Shortcut from "@/components/MenuShortcut.vue";
import Utils from "@/utils";
export default {
  components: {
    DeviceSettingDialog,
    Shortcut,
  },
  computed: {
    isBusiness() {
      return this.$store.getters.business;
    },
    isSwift() {
      return this.$store.getters.swift;
    },
    countCurrency() {
      return Utils.getSwiftCurrency().length;
    },
    accessRole() {
      return this.$store.getters.accessrole;
    },
  },
};
</script>
